.copyToClipboardContainer {
  color: #fff;
  display: flex;
  padding: 2px;
  flex: 1;
  border-radius: 5px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: 10px;
  border: 1px solid #121212;
  background: #121212;
  position: relative;
}

.lightModeContainer {
  border: 1px solid #f2f2f2;
  background: #f2f2f2;
}

.linkTextArea {
  width: 100%;
  border-width: 0px;
  font-size: 18px;
  outline: none;
  resize: none;
  box-sizing: border-box;
  padding-left: 10px;
  font-family: monospace;
  background: #121212;
  color: #aeaeae;
}

.lightTextArea {
  background: #f2f2f2;
  color: #000000;
}

.copyButton {
  border-width: 1px;
  font-family: inherit;
  font-size: 18px;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  border-color: #434343;
  background: #434343;
  color: #aeaeae;
}

.lightModeButton {
  border-color: #e2e2e2;
  background: #e2e2e2;
  color: #000000;
}

.linkIcon {
  font-size: 20px;
  color: #aeaeae;
}

.copyIcon {
  font-size: '15px';
}

.textAreaContainer {
  flex: 1;
}

.openLink {
  text-align: center;
  margin-top: 10px;
}
.openLink a {
  text-decoration: none;
  display: inline-block;
  border: 1px solid #434343;
  padding: 5px 10px;
  border-radius: 4px;
  transition: 180ms;
  font-size: 12px;
}
.darkOpenLink a:hover {
  color: #fff !important;
  border-color: #fff;
}

.lightOpenLink a:hover {
  color: #aeaeae !important;
  border-color: #aeaeae;
}

.fullWidth {
  width: 100%;
}

.pinDisplay {
  margin-top: 50px;
  text-align: center;
  margin-bottom: 50px;
}

.pinDisplayMsg {
  font-size: 12px;
}

.pinValidMsg {
  font-size: 11px;
  margin-bottom: 15px;
}

.labelLinkTextArea {
  display: block;
  position: absolute;
  top: -20px;
  left: 0px;
  font-size: small;
  color: #aeaeae;
}

@media screen and (min-width: 640px) {
  .fullWidth {
    display: flex;
    flex-flow: wrap;
  }
  .fullWidth > div {
    flex: 1 auto;
  }
  .pinDisplay {
    margin: 0;
  }
  .pinDisplayMsg {
    width: 100%;
    font-size: 18px;
    padding-bottom: 20px;
  }

  .openLink {
    width: 100%;
    flex: 1 auto;
  }

  .divider {
    margin: 30px 0;
    border-top: 1px solid #434343;
    width: 100%;
  }
}

.qrCodeContainer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}


.qrButton {
  border-width: 1px;
  font-family: inherit;
  font-size: 18px;
  width: 110px;
  height: 40px;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  border-color: #434343;
  background: #4bb2f9;
  color: white;
  margin-left: 5px;
}

.qrButton:hover {
  background-color: #0056b3; 
}

.QrCodeContent{
  display: flex;
  align-items: center;
}


