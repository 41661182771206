.toggleIcon {
  font-size: 24px;
  padding-top: 5px;
  cursor: pointer;
}

.FiMoon {
  color: #231709;
}

.FiSun {
  color: #4bb2f9;
}
