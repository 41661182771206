.userInfo {
  padding: 15px 0px 15px 0px;
  font-weight: 400;
  display: grid;
  grid-template-columns: 2fr auto auto;
  grid-column-gap: 15px;
  align-content: center;
  align-items: center;
}
.userInfoOnline {
  color: #4caf50;
}

@media screen and (max-width: 640px) {
  .userInfo {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.FiMoon {
  color: #231709;
}

.FiSun {
  color: #4bb2f9;
}

.userOnlineWaiting {
  display: flex;
  align-items: center;
}

.retryImageIcon {
  width: 20px;
  height: auto;
  margin-left: 5px;
  cursor: pointer;
}

.loading {
  animation: spin 5000ms linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
