.messageInfo {
  margin-bottom: 25px;
  width: 80%;
}

.messageLeft {
  justify-content: flex-start;
  display: flex;
  -webkit-animation: fade-in-left 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-left 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.messageRight {
  justify-content: flex-end;
  display: flex;
  -webkit-animation: fade-in-right 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-right 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.messageLeft + .messageLeft .sentReceived {
  display: none;
}

.messageRight + .messageRight .sentReceived {
  display: none;
}

.messageLeft + .messageRight {
  margin-top: 25px;
}

.messageRight + .messageLeft {
  margin-top: 25px;
}

.messageContainer {
  background-color: #363636;
  padding: 10px;
  font-size: 18px;
  min-height: 50px;
  word-break: break-word;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.messageContainer .timestamp {
  text-align: right;
  font-size: 13px;
  color: grey;
}

.sentReceived {
  margin-bottom: 3px;
  font-size: 13px;
  font-weight: 600;
}

.lightModeContainer {
  background-color: #d3d3d3;
}

.messageError {
  color: #828282;
  font-size: 13px;
}
.messageDelivered {
  color: #828282;
  font-size: 13px;
}

.messageRetry {
  display: inline-block;
  cursor: pointer;
  color: #0f8cff;
  margin-left: 5px;
}

@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px) translateY(60px);
    transform: translateX(-50px) translateY(60px);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px) translateY(60px);
    transform: translateX(-50px) translateY(60px);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
