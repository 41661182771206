.emojiRow {
  font-size: 18px;
  resize: none;
  flex: 1;
  border: 0;
  height: 200px;
  box-sizing: border-box;
  outline: none;
  padding: 5px 5px;
  user-select: none;
  scroll-behavior: smooth;
  overflow-y: scroll;
  overflow-x: hidden;
}

.lightEmojiRow {
  background: #f2f2f2;
}
