.imagePickerContainer {
  margin: 0 10px;
}

.imagePickerLabel {
  cursor: pointer;
}

.inputImagePicker {
  display: none;
}

.imagePickerIcon {
  width: 45px;
  height: auto;
  background-color: rgba(250, 245, 245, 0);
}

.dialog {
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.dialogContent {
  margin: auto;
  display: block;
  width: 65%;
  max-width: 700px;
}

@media only screen and (max-width: 600px) {

  .imagePickerIcon {
    width: 40px;
    height: auto;
    background-color: rgba(250, 245, 245, 0);
  }

}
