.button {
  padding: 10px 20px;
  display: inline-block;
  border-radius: 3px;
  border-color: transparent;
  font-size: 16px;
  opacity: 1;
  font-family: inherit;
  transition: 180ms;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  background: transparent;
  border: 1px solid #434343;
  color: #434343;
}

.darkMode {
  background: #434343;
  color: #fff;
}

.lightMode {
  background: #3cb043;
  color: #fff;
}

.primary {
  background: #e91e63;
  color: #fff;
}
/* 
.secondary {
  
} */
