.removeButtonContainer {
  margin: auto;
  width: 50px;
  height: 50px;
}

.checkmark {
  width: 30px;
  height: 30px;
  margin: 10px;
}

.darkMode {
  color: #fff;
  background: #000;
}

.checkmark:hover {
  cursor: pointer;
  animation: growIn 0.3s forwards;
}

.lightMode {
  color: #231709;
  background: rgba(0,0,0,0);
}

@keyframes growIn {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  100% {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
}
