.linkGenerationPage .linkGenerationBtnContainer {
  text-align: center;
}
.linkGenerationPage .header {
  padding: 20px;
  padding-left: 0px;
  font-size: 24px;
}

.linkGenerationPage .generate-link-btn {
  background: #4caf50;
  color: #fff;
  padding: 10px;
  width: 180px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
}

.linkGenerationPage .generate-link-btn.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.linkGenerationPage .captchaHeightSetter {
  min-height: 75px;
  display: flex;
  align-items: center;
}

.linkGenerationPage .title {
  font-size: 18px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.darkModeHeader {
  color: #4bb2f9;
}

.lightModeHeader {
  color: #231709;
}

.sectionContribute {
  margin: 40px 0px 40px 0px;
}

.sectionDefault {
  background: #212121;
  padding: 20px;
  box-shadow: 4px 3px 10px #121212;
  border-radius: 5px;
}

.sectionDefaultLight {
  background: #e3e3e3;
  padding: 20px;
  box-shadow: 4px 3px 10px #eeeeee;
  border-radius: 5px;
}
