html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  box-sizing: border-box;
}

body {
  font-family: monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: auto;
}

body {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
body::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.defaultMode {
  background: #121212;
  color: #aeaeae;
  height: 100%;
}

.bodyContent {
  max-width: 800px;
  margin: auto;
  height: 98%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media screen and (max-width: 640px) {
  .section--default {
    border-radius: 0px;
  }
}

a {
  color: inherit !important;
}

.fullWidth {
  width: 100%;
}

.lightMode {
  background: #f2f2f2;
  color: #000000;
}

@media only screen and (max-width: 900px) {
  .bodyContent {
    max-width: 700px;
  }
}

@media only screen and (max-width: 800px) {
  .bodyContent {
    max-width: 600px;
  }
}

@media only screen and (max-width: 640px) {
  .bodyContent {
    max-width: 95%;
  }
}
